body {
  /* Account for windows being draggable outside the "desktop" */
  background-color: #007d7b;
}

p {
  cursor: default;
}

.desktop { 
  display: flex; 
  justify-content: center; 
  align-items: center; 
  height: 100vh; 
  width: 100vw;
  
}

.main-window {
  width: 480px;
  position: absolute;
}

@media only screen and (max-width: 768px) {
  .main-window {
    width: 320px;
  }
  .main-window img {
    width: 125px;
    height: 125px;
  }
}

.main-window-text {
  margin-left: 6px;
}

.about-window {
  width: 350px;
  position: absolute;
}

.contact-window {
  width: 350px;
  position: absolute;
}

.top-pad-row {
  padding-top: 15px;
}

.center-section {
  justify-content: center;
}

.row-align-start {
  justify-content: flex-start;
}

.img-frame-parent {
  padding: 2px !important;
  background: inherit !important;
}

.img-frame-child {
  margin-top: 0px !important;
}

.contact-field-row {
  width: 350px;
}

.contact-field {
  width: 90%;
}

.contact-button {
  width: 10%;
  min-width: 50px;
  margin-top: 0px;
}
